import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';

export default {

  properties: {

    deliveryContact: {
      type: 'object',
      ...queryBuilder.if({ isDifferentFromOwner: true }),
      then: {
        properties: {
          address: {
            ...queryBuilder.properties({
              salutation: { type: 'number', required: true },
              firstName: { type: 'string', required: true },
              lastName: { type: 'string', required: true },
              street: { type: 'string', required: true },
              houseNumber: { type: 'string' },
              postalCode: { type: 'string', required: true },
              city: { type: 'string', required: true },
            }),
          },
        },
      },
    },
  },
} as IJSONSchema;
