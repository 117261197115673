











import Vue from 'vue';
import { StepData } from '@/src/components/processes/rs-wizard.vue';
import CreateSubscriptionContractRequestViewModel from '@/src/services/viewModel/resource/CreateSubscriptionContractRequestViewModel';
import oneSchema from './schema/1.schema';
import twoSchema from './schema/2.schema';
import threeSchema from './schema/3.schema';
import fourSchema from './schema/4.schema';
import fiveSchema from './schema/5.schema';
import sixSchema from './schema/6.schema';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    title: 'page.newGastroJournalSubscription.page.title',
    // TODO: Add proper Model here
    model: CreateSubscriptionContractRequestViewModel,
    steps: [
      {
        breadcrumb: 'page.subscription.label.stepTitles.1',
        component: () => import('./newGastroJournalSubscription1.vue'),
        schema: oneSchema,
        sendData: false,
      },
      {
        breadcrumb: 'page.subscription.label.stepTitles.2',
        component: () => import('./newGastroJournalSubscription2.vue'),
        schema: twoSchema,
        sendData: false,
      },
      {
        breadcrumb: 'page.subscription.label.stepTitles.3',
        component: () => import('./newGastroJournalSubscription3.vue'),
        schema: threeSchema,
        sendData: false,
      },
      {
        breadcrumb: 'page.subscription.label.stepTitles.4',
        component: () => import('./newGastroJournalSubscription4.vue'),
        schema: fourSchema,
        sendData: (data: any) => !data.orderBasket?.isOnlinePayment,
      },
      {
        breadcrumb: 'page.subscription.label.stepTitles.5',
        component: () => import('./newGastroJournalSubscription5.vue'),
        schema: fiveSchema,
        condition: (data: any) => data.orderBasket?.isOnlinePayment,
        sendData: true,
      },
      {
        breadcrumb: 'page.subscription.label.stepTitles.6',
        component: () => import('./newGastroJournalSubscription6.vue'),
        schema: sixSchema,
        sendData: false,
        disablePreviousSteps: true,
        clearProgressOnMounted: true,
      },
    ] as StepData[],
  }),
  methods: {
    initializeData() {
      const initializedModel = new CreateSubscriptionContractRequestViewModel().fromDTO({
        billingContact: { address: {} },
        deliveryContact: { address: {} },
        orderBasket: { onlinePayment: {} },
      } as Parameters<CreateSubscriptionContractRequestViewModel['fromDTO']>[0]);
      return initializedModel;
    },
    async serviceFunction(model: CreateSubscriptionContractRequestViewModel) {
      await this.$service.api.subscriptions.addGastroJournalSubscription(model);
      try {
        await this.$auth.refresh();
      } catch (error) {
        console.error('Unable to refresh authentication');
        console.error(error);
      }
    },
  },
});
