import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';

export default {
  ...queryBuilder.properties({
    languageIso2: { required: true, type: 'string' },
    orderBasket: {
      type: 'object',
      properties: {
        items: { type: 'array', required: true },
      },
    },
  }),
};
